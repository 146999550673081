<template>
<div>
  <v-col cols="12" class="k-ask-change">
    <v-row  justify="end" >
      <v-btn
        color="blue darken-3"
        class="white--text mr-5"
        @click="goTo()"
      >
        <slot>
          Solicitar Mudança
        </slot>
        
        <v-icon right dark>add_circle</v-icon>
      </v-btn>
    </v-row>
  </v-col>
</div>
</template>
<script>

export default {
  name : "AskChangeBtn",
  components: {
  },
  data(){
    return {
    }
  },
  methods: {
    openSnackbarService(){
      this.$store.commit('snackbar/serviceState', true);
    },
    goTo(){
      this.$router.push({ name: 'ShoppingList' });

    },
  },
}

</script>
<style lang="sass" scoped>
  .k-ask-change
    margin-bottom: 20px
</style>
